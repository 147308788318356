import React, {useEffect} from 'react';
import {graphql} from 'gatsby';
import {Link, Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';
import Layout from '../components/layout';
import SEO from '../components/seo';

/**
 * Inner Page Component of the website
 * @returns {JSX.Element}
 * @private
 */
const InnerPage = (props) => {
    const {t} = useTranslation();
    const {languages, originalPath} = useI18next();

    const {
        breadcrumbs, // @type [{title: string, url: string|undefined}]
        pageTitle, // string
        mode, // [inner-page|blog], default: inner-page
        children
    } = props;

    const genBreadcrumbsEl = (list) => {
        const el = list?.map((x, i) => {
            if (x.url) {
                return <li key={i}>
                    <Link to={x.url}>{x.title}</Link>
                </li>
            }
            else {
                return <li key={i}>
                    {x.title}
                </li>
            }
        })

        return el;
    }

    useEffect(() => {

    }, [])

    return (
        <main id={"main"}>
            {/*======= Breadcrumbs =======*/}
            <section className="breadcrumbs">
                <div className="container">

                    <ol>

                        {/*<li><a href="index.html">Home</a></li>*/}
                        {/*<li>Inner Page</li>*/}
                        {genBreadcrumbsEl(breadcrumbs)}
                    </ol>
                    <h2>{pageTitle}</h2>

                </div>
            </section>
            {/*End Breadcrumbs*/}

            <section className={`${mode === "blog" ? "blog" : "inner-page"}`}>
                <div className="container" data-aos="fade-up">
                    {/*{mode === "blog" ? children : <p>{children}</p>}*/}
                    {/*<p>*/}
                    {/*    {children}*/}
                    {/*</p>*/}
                    {children}
                </div>
            </section>
        </main>
    );
};

export default InnerPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;