import React, {useEffect} from 'react';
import {graphql} from 'gatsby';
import {Link, Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';
import Layout from '../components/layout';
import SEO from '../components/seo';
import parse from "html-react-parser";

/**
 * Error Block Components
 * @returns {JSX.Element}
 * @private
 */
const ErrorBlock = () => {
    const {t} = useTranslation();
    const {languages, originalPath} = useI18next();


    useEffect(() => {

    }, [])

    return (
        <>
            {t('errorTryAgain')}<br/><br/>
            <p><strong>{parse(t('occursManyContactUsHTML'))}</strong></p>
        </>
    );
};

export default ErrorBlock;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;